import { RouteComponentProps } from "react-router";

export const languages = ["en","es","fr","zh","my"];

export interface CardProps{
    fondo: string;
    nombre: string;
}

export interface MenuSitios{
  title:string;
  loc:string;
  rank:number;
  cantrw:number;
  prec:number;
  img:string;
}

export interface ContainerProps {
  onFinish: () => void;
}

export interface RestaurantProps{
  nombre: string,
  direccion:string, 
  imageUrl: string, 
  calificacion: number, 
  open: string
}

export interface FoodItemsProps {
  nombre: string, 
  descripcion:string, 
  ingredientes: object[],
  urlimagen: string,
  nombreIngrediente: string[],
  imageIngrediente: string[],
  estatusEndemico: boolean[],
  descripcionEndemico: string[]
  // beneficios: string[]
}

export interface Marcador {
  id: string,
  nombreEstacion?: string,
  nombrePolo?: string,
    descripcion: string,
    lat: number,
    lng: number,
		telefono: string,
		direccion: string,
		horarios?: string,
		sitioweb: string,
    image1?: string,
		categoria?: number,
		tramo?: number,
    polo?: number
    imagenes?:{
      id: string,
      idMedia: string,
      nombreEstacion: string,
      tamano: string,
      url: string
    }[]
}

export interface ArtesaniasProps {
  nombrePieza?: string,
  nombreAutor: string,
  localidad: string,
  descripcion: string,
  historia: string,
  proceso: string,
  precio: number,
  urlImg: string
}

export interface ToursProps {
  estacion: string,
  titulo: string,
  imgPortada: string,
  precio: number,
  sitioWeb: string,
  actividades: string[],
  imgActividades: string[],
  telefono: string,
  incluye: string[]
}

export interface TurComProps {
  id: string
  nombre: string,
  empresa: string,
  localidad: string,
  estado: string,
  titulo: string,
  programa: string,
  latitud: number,
  longitud: number,
  heroImage: string,
  sitioWeb: string,
  descripcion: string,
  actividades: string[],
  imagenes: string[],
  datos_contacto: string[],
  incluye: string[]
}

export interface HospedajeProps {
  nombre: string,
  direccion: string,
  telefono: string,
  precio: string,
  urlImage: string,
  resena: string,
  calificacion: number,
  incluye: string [],
  imgActividades: string[]
}

export interface ArqProps {
  nombre: string,
  traduccion: string,
  municipio: string,
  descripcion: string,
  horario: string,
  sitio_web: string,
  precio: number,
  tipo: string
  urlImage: string,
  promocion: string,
}

export interface MuseoProps {
  nombre: string,
  traduccion?:string,
  municipio: string,
  latitud?: string,
  longitud?: string,
  descripcion: string,
  horario: string,
  sitio_web: string,
  precio: any,
  tipo: string
  urlImagen: string[],
  promocion: string,
}

export interface BancoProps {
  nombre: string,
  traduccion?:string,
  municipio: string,
  latitud?: string,
  longitud?: string,
  descripcion: string,
  horario: string,
  sitio_web: string,
  tipo: string
  urlImagen: string[]
}

export interface ZonaArqProps {
  nombre: string,
  traduccion?:string,
  municipio: string,
  latitud?: string,
  longitud?: string,
  descripcion: string,
  horario: string,
  sitio_web: string,
  tipo: string
  urlImagen: string[]
}

export interface PuebloMagicoProps {
  nombre: string,
  traduccion?:string,
  municipio: string,
  latitud?: string,
  longitud?: string,
  descripcion: string,
  horario: string,
  sitio_web: string,
  tipo: string
  urlImagen: string[]
}

export interface ZonaRestProps {
  nombre: string,
  traduccion?:string,
  municipio: string,
  latitud?: string,
  longitud?: string,
  descripcion: string,
  horario: string,
  sitio_web: string,
  tipo: string
  urlImagen: string[]
}

export interface ZonaCoopProps {
  nombre: string,
  traduccion?:string,
  municipio: string,
  latitud?: string,
  longitud?: string,
  descripcion: string,
  horario: string,
  sitio_web: string,
  tipo: string
  urlImagen: string[]
}

export interface Tipo
  extends RouteComponentProps<{
    nom: string;
  }>{nom: string}

  export interface infoexpyuc{
    label: string,
    values: string[]
  }
  export interface infodisyuc{
    label: string,
    values: string
  }
  export interface infoactyuc{
    text: string,
    url: string
  }

  export interface infocooyuc{
    latitud: number,
    longitud: number
  }

  export interface ExpYucatanProps {
    id: number,
    lugar: string,
    nombre: string,
    imagen_prin: string,
    nombre_corto: string,
    informacion: infoexpyuc[],
    regiones: string,
    imagenes: string[],
    descripcion: string,
    actividades: infoactyuc[],
    distancias: infodisyuc[],
    sugerido_para: string,
    latitud: number,
    longitud: number
  }

  export interface SabYucatanProps {
    id: number,
    lugar: string,
    nombre: string,
    imagen_prin: string,
    nombre_corto: string,
    informacion: infoexpyuc[],
    descripcion: string,
    regiones: string,
    datos_interes: infoexpyuc[],
    imagenes: string[],
    distancias: infodisyuc[],
    latitud: number,
    longitud: number
  }