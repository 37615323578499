import React, { useState } from 'react';
import { IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonContent, IonHeader, IonIcon, IonImg, IonItem, IonLabel, IonList, IonListHeader, IonModal, IonTitle, IonToolbar, IonicSlides } from '@ionic/react';

import location from '../assets/location-pin.svg'
import './CardTurCom.css'
import { SabYucatanProps } from '../../src/interfaces';
import { arrowBack, callSharp, cellularSharp, desktopSharp, linkSharp, logoFacebook, logoInstagram, logoPinterest, logoSnapchat, logoTiktok, logoTwitter, logoWhatsapp, logoYoutube, mailSharp, mapSharp } from 'ionicons/icons';
import closePage from '../assets/close-circle.svg'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/controller'
import { Autoplay, Keyboard, Pagination, Scrollbar, Zoom } from 'swiper';

import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/keyboard';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/zoom';
import '@ionic/react/css/ionic-swiper.css';

function CardSabYucatan({id,lugar,nombre, imagen_prin, nombre_corto, informacion, descripcion, regiones, datos_interes, imagenes, distancias, latitud, longitud} : SabYucatanProps) {
  const [isOpen1, setIsOpen1] = useState(false);
  console.log("CardSabYucatan");
  
  return (
    <>
      <IonCard id='cardArt' color="light" onClick={() => setIsOpen1(true)} /*routerLink='/Menu'*/ >
        <img className="CardRes" alt={nombre} src={imagen_prin} />
        <IonCardHeader>
          <IonCardTitle>{nombre_corto}</IonCardTitle>
        </IonCardHeader>

        <IonCardContent>
            <div className="open">
              {regiones}, Yucatán
            </div>
            <div className="open">
              <p>{nombre}</p>
            </div>
        </IonCardContent>
      </IonCard>

      <IonModal isOpen={isOpen1} keepContentsMounted={true}>
        <IonHeader className='art-ion-header'>
          <IonToolbar className='name-header-modal1 toolbar-modal-tour'>
              <IonButtons className='button-close'>
                <IonButton className='text-close-tour' onClick={() => setIsOpen1(false)}>
                  <img src={closePage} alt="imageClose" id='icon-close' />
                </IonButton>
              </IonButtons>
            <IonImg className='header-modal-img-tour'
              src={imagen_prin}
              alt={nombre}
            ></IonImg>
            <IonTitle>
                <div className="open">
                        {nombre}
                </div>
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        
        <IonContent fullscreen={true} className="ion-padding">
        
        <h3>{nombre_corto}</h3>
        <IonList>
            <IonItem>
            <p>
                {descripcion}
            </p>
            </IonItem>
        </IonList>
              <Swiper
                // spaceBetween={5}
                modules={[Autoplay, Keyboard, Pagination, Scrollbar, Zoom, IonicSlides]}
                autoplay={true}
                keyboard={true}
                pagination={{
                  dynamicBullets: true,
                }}
                scrollbar={true}
                zoom={true}
                slidesPerView={1}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
              >
              {imagenes.map((element, index) => 
            <>
              <SwiperSlide>
                <IonCard id='swiper-ioncard'>
                <img className="img-swiper" alt='Hola' src={element} />
                  <IonCardHeader>
                    <IonCardTitle>{nombre_corto}</IonCardTitle>
                  </IonCardHeader>
                </IonCard>
              </SwiperSlide>
                </>
              )
              }
              </Swiper>

        <IonList className='Incluye padd-incluye'>
          <IonListHeader>
            <IonLabel className='title-incluye'>Información </IonLabel>
          </IonListHeader>
          {
            informacion.map(element =>
              <>
                <IonItem className="terciary">
                  <h5>{element.label}</h5>
                </IonItem>
                <IonItem>
                  {element.values[0]}
                </IonItem>
              </>  
            )
          }
          
        </IonList>

        <IonList className='Incluye padd-incluye'>
          <IonListHeader>
            <IonLabel className='title-incluye'>Datos de Interés </IonLabel>
          </IonListHeader>
          {
            datos_interes.map(element =>
              <>
                <IonItem>
                  <h5>{element.label}</h5>
                </IonItem>
                <IonItem>
                  {element.values[0]}
                </IonItem>
              </>  
            )
          }
          
        </IonList>

        <IonList className='Incluye padd-incluye'>
          <IonListHeader>
            <IonLabel className='title-incluye'>Distancias </IonLabel>
          </IonListHeader>
          {
            distancias.map(element =>
              <>
                <IonItem>
                  <h5>{element.label}</h5>
                </IonItem>
                <IonItem>
                  {element.values}
                </IonItem>
              </>  
            )
          }
          
        </IonList>

        </IonContent>
      </IonModal>
    
    
    </>

  );
}


export default CardSabYucatan;
