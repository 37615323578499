import {  useIonModal } from '@ionic/react';
import React, { useState } from 'react';
import {Marker} from 'react-leaflet'
import { ExpYucatanProps } from '../interfaces';

import L from 'leaflet';
import ExpYucatanModal from './ExpYucatanModal';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconkaan from '/assets/icon/inpi.png';

const DefaultIcon = L.icon({
    iconUrl: icon,
    iconSize: [25, 25], // size of the icon
});
L.Marker.prototype.options.icon = DefaultIcon;

const MarkersSitesI = ({sitios,Icon}:{sitios:ExpYucatanProps[], Icon: string}) => {
    const [selectedMarker, setSelectedMarker] = useState<ExpYucatanProps>();

    const customIcon = L.icon({
        iconUrl: Icon,
        iconSize: [25, 25] // size of the icon
    });

    const [present, dismiss] = useIonModal(ExpYucatanModal,{
        marker: selectedMarker,
        onDismiss: () => dismiss()
      });

    const modalOptions = {
        onDidDismiss: ()=>dismiss()
    }

    const markers = sitios.map((item,i) =>(
        <Marker key={i} position={[Number(item.latitud),Number(item.longitud)]} icon={(Icon==="")?(DefaultIcon):(customIcon)}
        eventHandlers={{
            click: () => {
                setSelectedMarker(item);
                present(modalOptions);
            },
          }}>
        </Marker>
    ))
    return <>{markers}</>;
};

export default MarkersSitesI;