import React from 'react';
import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonImg, IonPage, IonTitle,  IonToolbar, useIonRouter, withIonLifeCycle } from '@ionic/react';
import './Tab1.css';
import { compass, scan, } from 'ionicons/icons';
import ipn from '../assets/ipnb.png'

import Carrusel from "../components/Carrusel2"

import Mapa from '../components/LeafletMap';
import SelectLang from '../components/selectLanguage';
import { useHistory } from 'react-router';
import TouristAssistant from '../components/TouristAssistant';


const Tab1: React.FC = () => {
  const router = useIonRouter();
  const history = useHistory();

  const navigate = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    console.log(event.target);
    router.push('/app/QR');
};

const navigateCompass = (event: React.MouseEvent<HTMLElement>) => {
  event.preventDefault();
  console.log(event.target);
  router.push('/app/Compass');
};

  return (
    <IonPage>
      <IonHeader >
        <IonToolbar className='custom'>
          {/*<div className="elements-tab1">
            <div className="QR-button">*/}
              <IonButtons slot="start">
              <IonButton onClick={(event) => navigate(event)}>
                <IonImg src={ipn} style={{ width: '32px', height: '32px' }} />
              </IonButton>
              <IonButton>
                <IonIcon size="large" color='light' icon={scan}></IonIcon>
              </IonButton>
              </IonButtons>

            {/*</div>*/}

           {/*<div className="nombre-marca">
              <h2>Mexico Profundo</h2>
            </div>*/}
            <IonTitle className="nombre-marca">Mexico Profundo</IonTitle>
            <IonButtons slot="end" className='ion-padding-end'>
            <IonButton onClick={(event) => navigateCompass(event)}>
              <IonIcon size="large" color='light' icon={compass}></IonIcon>
            </IonButton>
            <SelectLang iconColorClass='white-icon'/>
            </IonButtons>
         {/* </div>*/}
          {/* <IonSearchbar placeholder="¿A dónde vas?" className="custom">
            </IonSearchbar> */}
        </IonToolbar>
      </IonHeader>
      <div className="marquee">
        <div className="track">
          {/* <div className="content">&nbsp;Dr Simi: Lo mismo, pero más barato - FONACOT: Siempre a favor de los trabajadores - IMSS: Seguridad y Solidaridad Social - FINABIEN: Servicios Financieros&nbsp;</div> */}
          <div className="content">Yucatán es el lugar más seguro de todo México. La Ruta Puuc se caracteriza por las fachadas decoradas con mosaicos de piedra. Visita el Pueblo Mágico de Valladolid.</div>
        </div>
      </div>
      <IonContent scrollY={false} scrollX={false} className='hideBgfMap'>
        <Mapa/>
        <Carrusel/>
        <TouristAssistant />
      </IonContent>
      <div className="marquee">
        <div className="track">
          {/* <div className="content">&nbsp;Nacional Monte de Piedad, Lotería Nacional, CO Capital, Échale, Ejido Verde, Extensio, Fondify, Grameen de la Frontera, Handen Consultoría, Hipocampus, Iluméxico, Impacto Transformador, Kubo Financiero, Rayito de Luna, Revitaliza Consultores, Sarape Social, Somos Vía y SVX MX&nbsp;</div> */}
          <div className="content">Izamal es un Pueblo Mágico famoso por sus edificios pintados de amarillo y blanco. Yucatán tiene una de las gastronomías más ricas y variadas de todo el Mundo.</div>
        </div>
      </div>
    </IonPage>
  );
};

export default withIonLifeCycle(Tab1);
