import React, { useEffect, useState } from 'react';
import "./LeafletMap.css"
import { MapContainer, TileLayer} from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import L from 'leaflet';
import {Marker} from 'react-leaflet'
import Markers from './Markers';
import { Geolocation } from '@capacitor/geolocation';

import {useTranslation} from 'react-i18next'

import { changeLanguage } from 'i18next';

import icon from '../assets/logo.svg';

import estaciones from "../data/estacionesparaderostrenmaya.json"
import polos from '../data/Corredor/podebi.json'
import art_qroo from '../data/Corredor/artesanias_qroo.json'
import instal from '../data/Corredor/instalacionesenergia.json'
import infrae from '../data/Corredor/infraestructura.json'
import inter from '../data/estacionesCordoba.json'
import dataMuseums from '../data/museos_oaxaca.json'
import dataBancos from '../data/bancos-bienestar.json'
import dataZonasArq from '../data/zonas_arq_pos.json'
import dataZonasArqFR from '../data/zonas_arq_pos_fr.json'
import dataRestaurantes from '../data/restaurantes_pos.json'
import dataCooperativas from '../data/cooperativas_pos.json'
import dataFinanciera from '../data/financiera_pos.json'
import dataPueblosMagicos from '../data/pueblosmagicos_pos.json'


import dataTurComEsp from '../data/maya-ka-an.json'
import dataTurComMaya from '../data/maya-ka-an-lang-maya.json'
import dataTurComEng from '../data/maya-ka-an-lang-english.json'
import dataTurComFren from '../data/maya-ka-an-lang-french.json'
import dataTurComChin from '../data/maya-ka-an-lang-chinese.json'

import dataExpYucatan from '../data/experiencias_f1.json'
import dataSabYucatan from '../data/datasabores_f1.json'

import MarkersSites from './MarkersSites';
import MarkersSitesB from './MarkersSitesB';
import MarkersSitesC from './MarkersSitesC';
import MarkersSitesD from './MarkersSitesD';
import MarkersSitesE from './MarkersSitesE';
import MarkersSitesF from './MarkersSitesF';
import MarkersSitesG from './MarkersSitesG';
import MarkersSitesH from './MarkersSitesH';
import MarkersSitesI from './MarkersSitesI';
import MarkersSitesJ from './MarkersSitesJ';


const CurrentIcon = L.icon({
    iconUrl: icon,
    iconSize:     [30, 30], // size of the icon
});

const LeafletMap: React.FC = () => {
    const [t,i18n] = useTranslation("global");

    console.log('Language: ',i18n.language);

    const [state, setstate] = useState({
        currentLocation: {
            lat:19.441221,
            lng:-88.992000
        }
    });

    const langEnable = i18n.language;

    let dataTurCom = dataTurComMaya;
  
    switch(langEnable){
        case 'es':
          dataTurCom = dataTurComEsp;
            break;
        case 'en':
          dataTurCom = dataTurComEng;
            break;
        case 'fr':
          dataTurCom = dataTurComFren;
            break;
        case 'zh':
          dataTurCom = dataTurComChin;
            break;
        case 'maya':
          dataTurCom = dataTurComMaya;
            break;
    }
  
    const GetCurrentPosition = async () => {
        const [t,i18n] = useTranslation("global");

        const coordinates = await Geolocation.getCurrentPosition({enableHighAccuracy:true});
        setstate({currentLocation:{
            lat: coordinates.coords.latitude,
            lng: coordinates.coords.longitude
        }});
        console.log('Current position:', coordinates);
        console.log('Language: ',i18n.language);
      };

    useEffect(() => {
        window.dispatchEvent(new Event("resize"));

        const checkPermission = async () => {
            try {
                const status = await Geolocation.checkPermissions();
                if (status.coarseLocation) {
                    return true;
                }
            return false;
            } catch (error:any) {
                console.log(error.message);
            }
        };
        checkPermission();
        //GetCurrentPosition();
      }, []);

    /*useEffect(()=>{
        setTimeout(() => {
            GetCurrentPosition();
        }, 1000);
      },[state]);*/

    return (
        <MapContainer center={[state.currentLocation.lat,state.currentLocation.lng]} zoom={6}>
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url='https://tile.openstreetmap.org/{z}/{x}/{y}.png'
            />
            <Markers sitios={estaciones} Icon='/assets/icon/tren-bala-circle.svg'/>
            <Markers sitios={polos} Icon='/assets/icon/polo-desarrollo-circle.svg'/>
            <Markers sitios={art_qroo} Icon='/assets/icon/artesania-circle.svg'/>
            <Markers sitios={instal} Icon='/assets/icon/petroleo-circle.svg'/>
            <Markers sitios={infrae} Icon='/assets/icon/petroleo-circle.svg'/>
            <Markers sitios={inter} Icon='/assets/icon/tren-inter-circle.svg'/>
            <MarkersSites sitios={dataMuseums} Icon='/assets/icon/chac-circle.svg'></MarkersSites>
            <MarkersSitesB sitios={dataBancos} Icon='/assets/icon/banco-circle.svg'></MarkersSitesB>
            <MarkersSitesC sitios={dataZonasArq} Icon='/assets/icon/piramide-circle.svg'></MarkersSitesC>
            <MarkersSitesD sitios={dataRestaurantes} Icon='/assets/icon/molcajete-circle.svg'></MarkersSitesD>
            <MarkersSitesE sitios={dataCooperativas} Icon='/assets/icon/cooperativa-circle.svg'></MarkersSitesE>
            <MarkersSitesF sitios={dataFinanciera} Icon='/assets/icon/finan_telecom.svg'></MarkersSitesF>
            <MarkersSitesG sitios={dataPueblosMagicos} Icon='/assets/icon/pueblomagico-circle.svg'></MarkersSitesG>
            <MarkersSitesH sitios={dataTurCom} Icon='/assets/icon/icono-maya-kaan.png'></MarkersSitesH>
            <MarkersSitesI sitios={dataExpYucatan} Icon='/assets/icon/exp.png'></MarkersSitesI>
            <MarkersSitesJ sitios={dataSabYucatan} Icon='/assets/icon/sabores.png'></MarkersSitesJ>


            {/*<Marker position={[state.currentLocation.lat,state.currentLocation.lng]} icon={CurrentIcon}></Marker>*/}
        </MapContainer>
    );
};

export default LeafletMap;