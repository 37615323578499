import { IonCard, IonCardHeader, IonCardTitle, IonListHeader, IonButton, IonButtons, IonContent, IonHeader, IonImg, IonItem, IonLabel, IonList, IonTitle,  IonToolbar, IonicSlides } from '@ionic/react';
import React, {  useState } from 'react';
import { IonIcon } from '@ionic/react';
import { alarmSharp, callSharp, cellularSharp, desktopSharp, linkSharp, logoFacebook, logoInstagram, logoPinterest, logoSnapchat, logoTiktok, logoTwitter, logoWhatsapp, logoYoutube, mailSharp, mapSharp, medalSharp,} from 'ionicons/icons';
import { ExpYucatanProps, TurComProps } from '../interfaces';

// import required modules

import closePage from '../assets/close-circle.svg'
import location_white from '../assets/location-pin.svg'


//Estilos de cada carta
import './ExpYucatanModal.css'

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/controller'
import { Autoplay, Keyboard, Pagination, Scrollbar, Zoom } from 'swiper';

import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/keyboard';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/zoom';
import '@ionic/react/css/ionic-swiper.css';


const ExpYucatanModal = ({
    marker,
    onDismiss,
  }: {
    marker : ExpYucatanProps;
    onDismiss: () => void;
  }) => {
  
    return (
        <>
        <IonHeader className='art-ion-header'>
            <IonToolbar className='name-header-modal1 toolbar-modal-tour'>
                <IonButtons className='button-close'>
                    <IonButton className='text-close-tour' onClick={() => onDismiss()}>
                        <img src={closePage} alt="imageClose" id='icon-close' />
                    </IonButton>
                </IonButtons>
                <IonImg className='header-modal-img-tour'
                    src={marker.imagen_prin}
                    alt={marker.nombre_corto}
                >                    
                </IonImg>
                <IonTitle>
                    <div className="open">
                            {marker.nombre_corto}<br/>
                    </div>
                </IonTitle>
            </IonToolbar>
        </IonHeader>
        
        <IonContent fullscreen={true} className="ion-padding">
        
            <div className="open">
                <h5>{marker.nombre}</h5>
                {marker.regiones}, Yucatán
            </div>
            <IonList>
                <IonItem>
                <p>{marker.descripcion}</p>
                </IonItem>
            </IonList>
            <Swiper
                // spaceBetween={5}
                modules={[Autoplay, Keyboard, Pagination, Scrollbar, Zoom, IonicSlides]}
                autoplay={true}
                keyboard={true}
                pagination={{
                  dynamicBullets: true,
                }}
                scrollbar={true}
                zoom={true}
                slidesPerView={1}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
              >
              {marker.imagenes.map((element, index) => 
            <>
              <SwiperSlide>
                <IonCard id='swiper-ioncard'>
                <img className="img-swiper" alt='Hola' src={element} />
                  <IonCardHeader>
                    <IonCardTitle>{marker.nombre_corto}</IonCardTitle>
                  </IonCardHeader>
                </IonCard>
              </SwiperSlide>
                </>
              )
              }
              </Swiper>

        <IonList className='Incluye padd-incluye'>
          <IonListHeader>
            <IonLabel className='title-incluye'>Información</IonLabel>
          </IonListHeader>
          {
            marker.informacion.map(element =>
              <>
                <IonItem className="open">
                  <h5>{element.label}</h5>
                </IonItem>
                <IonItem className="open">
                  {element.values[0]}
                </IonItem>
              </>  
            )
          }
          
        </IonList>

        <IonList className='Incluye padd-incluye'>
          <IonListHeader>
            <IonLabel className='title-incluye'>Actividades</IonLabel>
          </IonListHeader>
          {
            marker.actividades.map(element =>
              <>
                <IonItem className="open">
                  <h5>{element.text}</h5>
                </IonItem>
                <IonItem className="open">
                  {element.url}
                </IonItem>
              </>  
            )
          }
          
        </IonList>

        <IonList className='Incluye padd-incluye'>
          <IonListHeader>
            <IonLabel className='title-incluye'>Distancias</IonLabel>
          </IonListHeader>
          {
            marker.distancias.map(element =>
              <>
                <IonItem className="open">
                  <h5>{element.label}</h5>
                </IonItem>
                <IonItem className="open">
                  {element.values}
                </IonItem>
              </>  
            )
          }
          
        </IonList>

        <IonList>
            <IonItem>
                <h5>Sugerido para:</h5>
            </IonItem>
            <IonItem>
                {marker.sugerido_para}
            </IonItem>
        </IonList>

        </IonContent>
        </>
    );
};

export default ExpYucatanModal;